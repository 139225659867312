// 老合约
export const config = {
    BondingCurveUtil: '0xBc5DCB0eAc49D07DBBC3A9D870aC8EaeF36D529a',
    LpLocker: '0x9B2c7B9e2176FFF24D881E068f69f9Fc35124D8c',
    Token: '0xdc0B33F6AFED31d45E2B0119d3e05f787B526684',
    BondingCurve: '0x6D0Dd453e827A3C89e93c38278e1f28b5D968840',
    BondingCurveHepler: '0x2E2F8a2b6F658DEe124185F5e4cE1d87928aC8D8',
    chainId: '8453', 
}
// 新合约
export const config2 = {
    BondingCurveUtil: '0x7D7DD204b4104057b8Bd141b0b14F22f3d3D5F77',
    LpLocker: '0x1391A4B8295243f92eA05dd7bd60e7026a36b960',
    Token: '0xc2e21d0a45cB3b1dC3E70CeD5E4203F8d3E2150A',
    BondingCurve: '0x43b97b95772f154c00196Fe84cb3352a71fFF274',
    BondingCurveHepler: '0xeF6925281D188E88cB54ad8eF111C10991dcac38',
    chainId: '8453', 
}
// 新合约 3
export const config3 = {
    BondingCurveUtil: '0x1443BeF262eB7Dce2c06f0913403eAEA3130FA49',
    LpLocker: '0x946BBdA51F96dFbb9e6AEbbe86B119e964Aad2f4',
    Token: '0x9d83A0529a7a7991c01a03c88ce75e5CF1C9B23C',
    BondingCurve: '0xD0F96132313E84f86Ca057b3092E1614a6D5638C',
    BondingCurveHepler: '0x4F020a547cA3c98013FD2FA1b4B8561c40CFAF61',
    chainId: '8453', 
}
export const rpc = 'https://mainnet.base.org'
export const lpV3positionManagerAddress = '0x03a520b32C04BF3bEEf7BEb72E919cf822Ed34f1'
export const rpcChainId = '0x2105'

// // start  测试环境
// export const config = {
//     BondingCurveUtil: '0x0fDEd431900f5809B06A92c1d924AB8344B35F3B',
//     LpLocker: '0xD0F96132313E84f86Ca057b3092E1614a6D5638C',
//     Token: '0x41d7F53Fda1C3cF737FFCc60bF5a8CFAaB6f92b3',
//     BondingCurve: '0x38eF38520B6109fe33FA9f931146266D46064deA',
//     BondingCurveHepler: '0xb4CB585a92F876eEfa27E3cEbB8bB62217E60dE1',
//     chainId: '84532', 
// }

// export const config2 = {
//     BondingCurveUtil: '0x2B9046899c1075B95aB12B6f34e1028290c56CA8',
//     LpLocker: '0xcbaa3C662Aa5c267409F1C142f49f8128724151F',
//     Token: '0x64AF76461E8C8D23B19D888735C010e11A4a7b0e',
//     BondingCurve: '0x083aBE848B054075D109FDe3625389d56789da22',
//     BondingCurveHepler: '0xC2caC7243089183550BeA4475dA4958Fa1E34C6C',
//     chainId: '84532', 
// }

// // 新合约 3
// export const config3 = {
//     BondingCurveUtil: '0x6187bE49Cc7293Ff504FC3E2b6EBfA79460b153c',
//     LpLocker: '0x5d721eA14c5fC8E4036b771f532656201bE82BE5',
//     Token: '0x734385eb2A597239e1e27D2956028Ca85000e9c2',
//     BondingCurve: '0x2853699D13E4A60Ce15195D0c67EaF8e5BB7C16b',
//     BondingCurveHepler: '0x29f0a13a5eDc02879B2f33243621f2bCa12cF2Ef',
//     chainId: '84532', 
// }
// export const rpc = 'https://sepolia.base.org'
// export const lpV3positionManagerAddress = '0x27F971cb582BF9E50F397e4d29a5C7A34f11faA2'
// export const rpcChainId = '0x14a34'
// // end

