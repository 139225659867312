<template>
  <div class="text-whitebase w-10 h-10 flex justify-center items-center" @click="toggleStatus">
    <i class="iconfont icon-guanbi font-24" v-if="$store.state.statusShow"></i>
    <div class="relative">
      <i v-if="!state.isMinted && !$store.state.statusShow" class="notMintDotted absolute" />
      <i v-if="!$store.state.statusShow" class="iconfont icon-caidan-31 font-24"></i>
    </div>
  </div>

  <transition name="fade">
    <div v-if="$store.state.statusShow"
      class="absolute bg-white left-0 mobile-box--shadow text-whitebase flex flex-col justify-between"
      :style="`top: 72px; ${(!isSignedIn ? 'height: calc(100vh - 70px);' : '')} width: 100vw`">
      <div class="mt-3">
        <div class="h-12 flex justify-end items-center font-bold font-18 pr-4 mobile-tab--btn"
          v-for="(header, i) in globalTabs" :key="i">

          <el-dropdown trigger="click" placement="bottom-start" @visible-change="handleChange" v-if="header.isDropDown">
            <div class="font-bold text-base text-white-060 hover:text-whitebase mt-2" :keys="header.key"
              :nav="$route.meta.nav" :class="{
                'hover-underline-animation': !header.disabled,
                'global-nav--active underline-animation':
                  $route.meta.nav == header.key,
                'global-nav--lighter': !$route.meta.nav,
                'global-nav--disabled': header.disabled,
              }">
              {{ header.name }}
              <span class="ml-1 icon iconfont" :class="{
                'icon-right': !state.isOpen,
                'icon-xia1': state.isOpen,
              }">
              </span>
            </div>

            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item v-for="(option, i) in header.dropDownLinks" :key="i" :command="option.link"
                  class="h-10 px-2 py-1.5 text-white-070 text-sm text-bold line-clamp-1"
                  :class="{ el_tab_active: $route.meta.type === option.key }" style="min-width: 144px">
                  <tippy content="Coming Soon" v-if="option.disabled" :arrow="false">
                    <div class="text-white-040 cursor-not-allowed flex gap-1">
                      <img v-if="url.indexOf(option.link) > -1" :src="`/img/header/${option.img}.svg`" />
                      <img v-else :src="`/img/header/${option.img}-1.svg`" />
                      {{ option.name }}
                    </div>
                  </tippy>

                  <router-link :to="option.link" v-else-if="!option.target">
                    <div class="flex gap-1">
                      <img v-if="url.indexOf(option.link) > -1" :src="`/img/header/${option.img}-1.svg`" />
                      <img v-else :src="`/img/header/${option.img}.svg`" />
                      {{ option.name }}
                    </div>
                  </router-link>

                  <a :href="option.link" target="_self" class="flex gap-1" v-else>
                    <img v-if="url.indexOf(option.link) > -1" :src="`/img/header/${option.img}-1.svg`" />
                    <img v-else :src="`/img/header/${option.img}.svg`" />
                    {{ option.name }}
                  </a>
                </el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>

          <tippy :content="header.tip" v-else-if="header.disabled" :arrow="false">
            <div class="hover:text-whitebase hover-underline-animation">
              {{ header.name }}
            </div>
          </tippy>

          <router-link :to="header.link" v-else-if="!header.target">
            <div class="flex items-center mt-2">
              <div v-if="header.name" class="hover:text-whitebase" :class="{
                'hover-underline-animation': !header.disabled,
                'global-nav--active underline-animation':
                  $route.meta.nav == header.key,
              }">
                {{ header.name }}
              </div>
              <img v-if="header.img" :src="header.img" class="w-5 h-5 ml-2" />
              <img class="w-20 mt-1" v-if="header.key !== 'honeyPot' && header.imgName" :src="header.imgName" />
            </div>
          </router-link>

          <a v-else :href="header.link" :target="header.target">
            <div class="hover:text-whitebase mt-2" :class="{ 'hover-underline-animation': !header.disabled }">
              {{ header.name }}
            </div>
          </a>

          <a v-if="header.key === 'honeyPot' && header.imgName" :href="header.link" :target="header.target">
            <img class="w-20 mt-1" :src="header.imgName" />
          </a>

        </div>
      </div>

      <div class="w-4/5 mx-auto mb-40">
        <UiButton v-if="!isSignedIn" class="rounded" style="height: 52px" type="main-white" @click="mittWalletSelect">
          Sign in</UiButton>

        <div class="flex flex-col items-end space-y-8" v-else>
          <div class="font-bold flex gap-2" @click="
            toProfile();
          state.open = false;
          ">
            <span class="active-button daoBadgeButton"><i class="daoBadgeIcon" />&nbsp;DAO Badge</span>
            Profile
          </div>
          <div class="font-bold" @click="doSignoutFunc">Sign Out</div>

          <div class="flex justify-end">
            <div>
              <div class="mb-1" @click="copyTx(userInfo.address)">
                <i class="iconfont icon-fuzhi2-29 mr-1"></i>
                <span>{{ formatAddress(userInfo.address, 5) }}</span>
              </div>
              <div class="flex justify-end">
                <div class="rounded h-4 flex items-center text-mb-red mobile-network--line font-12 px-2">
                  {{ chainx }}
                </div>
              </div>
            </div>
            <UiImg class="w-10 h-10 rounded-full overflow-hidden ml-3" :src="userInfo.icon"></UiImg>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script setup>
import { computed, onMounted, onUnmounted, reactive, watch, ref } from 'vue';
import UiButton from '@/components/UiButton.vue';
import { useSigninCheck, useUserInfo, useNetwork, useSignOut } from '@/hooks';
import { withThrottling } from '@/utils/with';
import UiImg from '@/components/UiImg.vue';
import { formatAddress, copyTx } from '@/utils';
import { useStore } from 'vuex';
import { mittWalletConnectSignOut, mittWalletSelect } from '@/libs/event';
import { getMintedPersonalData, getProfile } from '@/api/dao';
import { contracts } from '@/constants';
import { notify } from '@kyvg/vue3-notification';
import { useRouter, useRoute } from 'vue-router';

const $route = useRoute();
const $router = useRouter();
const $store = useStore();
const x = ref(null);
const url = ref(location.href)

const userInfo = useUserInfo();
const isSignedIn = computed(() => !!userInfo.value.address);

const chainx = useNetwork();

const state = reactive({
  open: false,
  isMinted: true,
  userInfo,
  switch: false,
  isOpen: false,
});

const handleChange = (newState) => {
  state.isOpen = newState;
};

const globalTabs = [
  {
    name: 'DAOs',
    link: '/hub',
    key: 'Creator',
  },
  {
    name: 'Leaderboard',
    link: '/leaderboard',
    key: 'LeaderBoard',
  },
  {
    name: 'Ecosystem',
    key: 'Proposals',
    isDropDown: true,
    dropDownLinks: [
      {
        name: 'Proposals',
        link: '/proposals',
        key: 'proposals',
        img: 1,
      },
      {
        name: 'Members',
        link: '',
        key: 'xx',
        disabled: true,
        tip: 'coming soon',
        img: 2,
      },
      {
        name: 'Ecology',
        link: '/ecology',
        key: 'proposals',
        img: 3,
      },
      {
        name: 'DAO100',
        link: '/dao100/bytreasury',
        key: 'proposals',
        img: 4,
      },
    ],
  },
  // {
  //   name: 'Member',
  //   img: require('@/assets/icons/new.gif'),
  //   link: '/members',
  //   key: 'Members',
  // },
  {
    name: 'Earn',
    key: 'Points',
    isDropDown: true,
    dropDownLinks: [
      {
        name: 'Points',
        link: '/point',
        key: 'Points',
        img: 5,
      },
      {
        name: 'Staking',
        link: '',
        key: 'xx',
        disabled: true,
        tip: 'coming soon',
        img: 6,
      },
    ],
  },
  {
    name: 'NFTs',
    key: 'NFTs',
    isDropDown: true,
    dropDownLinks: [
      {
        name: 'DAO BadgeX',
        link: '/mintdaobadge',
        key: 'DAO_BadgeX',
        img: 7,
      },
      {
        name: 'Buzz’Bees',
        link: 'https://buzzingbees.daobase.ai/',
        target: '_blank',
        key: 'Buzz’Bees',
        img: 8,
      },
      // {
      //   name: 'NFT Value',
      //   link: 'https://nftvalue.daobase.ai/#/',
      //   key: 'NFT_Value',
      //   target: '_blank',
      //   img: 9,
      // },
      {
        name: 'NFT Value',
        link: '',
        key: 'xx',
        disabled: true,
        tip: 'coming soon',
        img: 9,
      },
    ],
  },
  // {
  //   name: 'Footprints',
  //   link: 'https://daobase.notion.site/DAO-BASE-Development-and-Operation-Footprints-8c85c5ed3a8e4a2f8dbb6188def3d3ba',
  //   target: '_blank',
  //   key: 'Footprints',
  // },
  {
    name: 'About',
    isDropDown: true,
    key: 'Blog',
    dropDownLinks: [
      {
        name: 'Blogs',
        link: '/blog',
        key: 'Blog',
        img: 10,
      },
      {
        name: 'Footprints',
        link: 'https://daobase.notion.site/DAO-BASE-Development-and-Operation-Footprints-8c85c5ed3a8e4a2f8dbb6188def3d3ba',
        key: 'xx',
        target: '_blank',
        img: 11,
      },
      {
        name: 'Medium',
        link: 'https://medium.com/@daobase_ai',
        key: 'xx',
        target: '_blank',
        img: 12,
      },
      {
        name: 'Wiki',
        link: 'https://docs.daobase.ai/daobase',
        key: 'xx',
        target: '_blank',
        img: 13,
      },
    ],
  },
  // {
  //   imgName: require('@/assets/logo/honey-pot.gif'),
  //   link: 'https://honeypot.daobase.ai/',
  //   target: '_blank',
  //   key: 'honeyPot',
  // },
  {
    imgName: require('@/assets/funToken/img17.png'),
    link: '/daopopinvite',
    key: 'honeyPot',
  },
];

const jump = (item) => {
  if (item.target) {
    window.open(item.link, item.target);
  } else {
    $router.push(item.link);
  }

  setTimeout(() => {
    $store.commit('toggleStatus', false);
  }, 300);
};

// TODO 原来登录的时候是刷新页面，现在可能就是要更新状态
const mSignin = useSigninCheck();
const signIn = withThrottling(async () => {
  try {
    await mSignin();
  } catch (error) {
    console.log('mSignIn error::', error);
  }
});
const getIsMinted = async () => {
  if (state.userInfo?.isSignedIn) {
    await getPersonalPoints();
  }
};

const getPersonalPoints = async () => {
  try {
    const res = await getMintedPersonalData();
    if (res) {
      state.isMinted = true;
    } else {
      state.isMinted = false;
    }
  } catch (error) {
    // notify({
    //   type: 'error',
    //   text: 'could not get mint status!',
    // });
    console.error("could not get mint status::", error);
  }
};

onMounted(() => {
  getIsMinted();
});

const doSignout = useSignOut();

const doSignoutFunc = () => {
  mittWalletConnectSignOut();
  doSignout();
  location.reload();
};

const toProfile = () => {
  $router.push(`/address/details/${userInfo.value.address}`);
};

const toggleStatus = () => {
  $store.commit('toggleStatus', !$store.state.statusShow);
};

watch(
  () => state.open,
  (val) => {
    document.body.style.overflow = val ? 'hidden' : 'auto';

    const fn = (e) => {
      e.preventDefault();
    };

    if (val) {
      document.addEventListener('touchmove', fn);
    } else {
      document.removeEventListener('touchmove', fn);
    }
  },
);
</script>

<style lang="scss" scoped>
.mobile-box--shadow {
  box-shadow: 0px -8px 8px 0px rgba(255, 255, 255, 0.05);
}

.mobile-tab--btn:active {
  background: #ffffff1a;
}

.mobile-network--line {
  border: 1px solid #ff5585;
}

.notMintDotted {
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: #ff5585;
  right: -5px;
  top: 5px;
}

.profileIconBadgeDotted {
  width: 6px;
  height: 6px;
  border-radius: 100%;
  background: #ff5585;
  right: 0;
  top: 4px;
}

.daoBadgeButton {
  padding: 0 8px;
  height: 20px;
  border-radius: 10px;
  font-size: 0.6rem;
}

.daoBadgeIcon {
  width: 10px;
  height: 10px;
  border-radius: 10px;
  background: url('~@/assets/images/mintbadge/small.png') center center;
  background-size: 100%;
}
</style>
